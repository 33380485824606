<template>
    <div class="flex gap-2">
        <div class="flex flex-col">
            <select
                v-model="selectedMonth"
                class="border border-gray-300 p-2 text-[16px] focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] focus:outline-none focus:outline-offset-0"
                :class="monthInputClass"
                :disabled="disabled"
            >
                <option
                    :key="0"
                    :value="0"
                >
                    {{ $t('dropdownDateInput.month') }}
                </option>
                <option
                    v-for="month in monthsOptions"
                    :key="month.value"
                    :value="month.value"
                >
                    {{ month.name }}
                </option>
            </select>
            <small
                v-if="showMonthError"
                id="month-help"
                class="text-red-600 pl-2"
                >{{ $t('dropdownDateInput.monthRequired') }}</small
            >
        </div>
        <div class="flex flex-col">
            <select
                v-model="selectedDay"
                class="border border-gray-300 p-2 text-[16px] focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] focus:outline-none focus:outline-offset-0"
                :class="dayInputClass"
                :disabled="disabled"
            >
                <option
                    :key="0"
                    :value="0"
                >
                    {{ $t('dropdownDateInput.day') }}
                </option>
                <option
                    v-for="day in dayOptions"
                    :key="day.value"
                    :value="day.value"
                >
                    {{ day.name }}
                </option>
            </select>
            <small
                v-if="showDayError"
                id="day-help"
                class="text-red-600"
                >{{ $t('dropdownDateInput.dayRequired') }}</small
            >
        </div>
        <div class="flex flex-col">
            <select
                v-model="selectedYear"
                class="border border-gray-300 p-2 text-[16px] focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] focus:outline-none focus:outline-offset-0"
                :class="yearInputClass"
                :disabled="disabled"
            >
                <option
                    :key="0"
                    :value="0"
                >
                    {{ $t('dropdownDateInput.year') }}
                </option>
                <option
                    v-for="year in yearOptions"
                    :key="year"
                    :value="year"
                >
                    {{ year }}
                </option>
            </select>
            <small
                v-if="showYearError"
                id="year-help"
                class="text-red-600"
                >{{ $t('dropdownDateInput.yearRequired') }}</small
            >
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch, watchEffect } from 'vue'
import { isLeapYear, isValid, parseISO } from 'date-fns'
import { toTimelessIso } from '~/composables/support/localization'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    date: string
    showErrors: boolean
    disabled?: boolean
    invalid?: boolean
}>()

const emit = defineEmits<{
    (e: 'update:date', payload: string): void
}>()

const { t } = useI18n()
const selectedMonth = ref<number>(0)
const selectedDay = ref<number>(0)
const selectedYear = ref<number>(0)

const showMonthError = computed(() => {
    return selectedMonth.value === 0 && props.showErrors
})
const showDayError = computed(() => {
    return selectedDay.value === 0 && props.showErrors
})
const showYearError = computed(() => {
    return selectedYear.value === 0 && props.showErrors
})

const monthInputClass = computed(() => {
    if (props.disabled === true) {
        return 'text-gray-500'
    } else if (showMonthError.value || props.invalid) {
        return 'border-2 border-red-500'
    } else {
        return ''
    }
})

const dayInputClass = computed(() => {
    if (props.disabled === true) {
        return 'text-gray-500'
    } else if (showDayError.value || props.invalid) {
        return 'border-2 border-red-500'
    } else {
        return ''
    }
})

const yearInputClass = computed(() => {
    if (props.disabled === true) {
        return 'text-gray-500'
    } else if (showYearError.value || props.invalid) {
        return 'border-2 border-red-500'
    } else {
        return ''
    }
})

watch(
    toRef(props, 'date'),
    newValue => {
        // If the new value is resetting to blank, we only want to reset the internal state
        // if we have a completely valid value
        if (newValue === '') {
            if (
                selectedYear.value === 0 ||
                selectedMonth.value === 0 ||
                selectedMonth.value === 0
            ) {
                return
            }

            const newDate = parseISO(
                selectedYear.value +
                    '-' +
                    String(selectedMonth.value).padStart(2, '0') +
                    '-' +
                    String(selectedMonth.value).padStart(2, '0')
            )

            if (isValid(newDate)) {
                selectedMonth.value = 0
                selectedYear.value = 0
                selectedDay.value = 0
            }
        } else {
            const date = parseISO(props.date)
            if (!isValid(date)) {
                throw new Error('Error parsing date.')
            }

            selectedMonth.value = date.getMonth() + 1
            selectedYear.value = date.getFullYear()
            selectedDay.value = date.getDate()
        }
    },
    { immediate: true }
)

const monthsOptions = [
    { name: t('dropdownDateInput.january'), value: 1 },
    { name: t('dropdownDateInput.february'), value: 2 },
    { name: t('dropdownDateInput.march'), value: 3 },
    { name: t('dropdownDateInput.april'), value: 4 },
    { name: t('dropdownDateInput.may'), value: 5 },
    { name: t('dropdownDateInput.june'), value: 6 },
    { name: t('dropdownDateInput.july'), value: 7 },
    { name: t('dropdownDateInput.august'), value: 8 },
    { name: t('dropdownDateInput.september'), value: 9 },
    { name: t('dropdownDateInput.october'), value: 10 },
    { name: t('dropdownDateInput.november'), value: 11 },
    { name: t('dropdownDateInput.december'), value: 12 }
]

// First add 28 days to dayOptions, it will be set by the watchEffect below
const dayOptions = ref<{ name: string; value: number }[]>([])
for (let i = 1; i <= 28; i++) {
    dayOptions.value.push({ name: i.toString(), value: i })
}

// This code runs initially and when selectedMonth or selectedYear are changed
// It updates dayOptions accordingly
watchEffect(() => {
    // Reset the dayOptions options to just 28 days
    if (dayOptions.value.length > 28) {
        while (dayOptions.value.length > 28) {
            dayOptions.value.pop()
        }
    }

    // If no month has been selected, or we select a 31-day month
    // Set dayOptions options to be 31 days
    if (
        // 0 represents no selection here
        selectedMonth.value === 0 ||
        selectedMonth.value === 1 ||
        selectedMonth.value === 3 ||
        selectedMonth.value === 5 ||
        selectedMonth.value === 7 ||
        selectedMonth.value === 8 ||
        selectedMonth.value === 10 ||
        selectedMonth.value === 12
    ) {
        dayOptions.value.push({ name: '29', value: 29 })
        dayOptions.value.push({ name: '30', value: 30 })
        dayOptions.value.push({ name: '31', value: 31 })
        return
    }

    // If the selected month is February
    if (selectedMonth.value === 2) {
        // If the selected year is a leap year return 29 days
        if (
            selectedYear.value === 0 ||
            isLeapYear(new Date(selectedYear.value, 1))
        ) {
            dayOptions.value.push({ name: '29', value: 29 })
        }
        return
    }

    // Else we must have selected a 30-day month
    dayOptions.value.push({ name: '29', value: 29 })
    dayOptions.value.push({ name: '30', value: 30 })
})

// If our selectedDay is not present in dayOptions, unselect
// This covers the case of selecting the 31st, then switching the month to February
watchEffect(() => {
    if (
        !dayOptions.value.some(
            dayOption => dayOption.value === selectedDay.value
        )
    ) {
        selectedDay.value = 0
    }
})

const yearOptions: number[] = []
for (
    let i = new Date().getFullYear() - 120;
    i <= new Date().getFullYear();
    i++
) {
    yearOptions.unshift(i)
}

watch(
    [selectedMonth, selectedYear, selectedDay],
    ([newMonth, newYear, newDay]) => {
        if (newMonth === 0 || newYear === 0 || newDay === 0) {
            emit('update:date', '')
            return
        }

        const newDate = parseISO(
            newYear +
                '-' +
                String(newMonth).padStart(2, '0') +
                '-' +
                String(newDay).padStart(2, '0')
        )

        if (isValid(newDate)) {
            emit('update:date', toTimelessIso(newDate))
        } else {
            emit('update:date', '')
        }
    }
)
</script>
